.text {
    font-size: 16pt;
    padding: 0 4vw;
    /* text-align: left; */
    line-height: 1.5;
    /* width: 100% */
}

.about-section {
    width: 100%;
}

.about-container {
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .about-section {
        width: 95%;
    }

    .text {
        font-size: 12pt;
    }
}