.icon {
    max-width: 50px;
    margin: 10px;
    /* filter: invert(); */
    /* background-color: aqua; */
}

@media screen and (max-width: 768px) {
    .icon {
        max-width: 36px;
        margin: 10px
    }
}