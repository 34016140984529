.contact-info {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow: hidden;
}

.expanded {
    width: 150px;
    /* Adjust the width based on your needs */
}

.toggle-button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
}

.contact-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    animation: slideIn 0.3s ease-out;
}

.contact-button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideIn {
    from {
        transform: translateY(20px);
    }

    to {
        transform: translateY(0);
    }
}