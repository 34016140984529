/* form {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.contactDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
}


.firstRow {
    display: flex;
    width: 80%;
}

.messageDiv {
    width: 100%;
    height: 300px;
}

textarea {
    width: 80%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    padding: 10px;
    margin: 20px;
}

input {
    background-color: #000;
    height: 40px;
    width: 90%;
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    padding-left: 10px;
}

input[type='submit'] {
    background-color: #fff;
    color: #000;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.2s ease-in-out;
    width: 40%;
    margin: 50px;
}

input[type='submit']:hover {
    background-color: #ccc;
} */

.contactDiv {
    max-width: 60%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 5px;
}

.contactDiv input[type="text"],
.contactDiv input[type="email"],
.contactDiv textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid white;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 16px;
}

.contactDiv textarea {
    height: 40vh;
}

.contactDiv input[type="submit"] {
    background-color: #fff;
    color: #000;
    border: none;
    padding: 10px 20px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    border: 1px solid white;
    border-radius: 10px;
    width: 80%;
}

.contactDiv input[type="submit"]:hover {
    background-color: #aaa;
}

.firstRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.each {
    flex-basis: 48%;
}

.messageDiv {
    margin: 10px 0;
}

@media screen and (max-width: 768px) {
    .each {
        flex-basis: 100%;
    }
}