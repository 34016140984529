.projectContainer {
    display: flex;
}

.imgContainer {
    width: 50%;
    max-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.projectImg {
    /* border: 2px white solid; */
    border-radius: 10px;
    max-width: 90%;
    max-height: 90%;
    margin: 10px;
}

.desContainer {
    width: 50%;
    height: 90%;
    /* background-color: red; */
    padding: 10px;
}

.description {
    /* background-color: blue; */
    font-size: 15pt;
    text-align: justify;
    text-justify: inter-word;
    margin-right: 20px;
}

.tag {
    border: 1px white solid;
    background-color: white;
    color: black;
    padding: 4px 10px;
    font-size: 15pt;
    border-radius: 10dvh;
    margin: 10px;
}

.tagContainer {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
    .projectContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .imgContainer {
        width: 95%;
    }

    .desContainer {
        width: 95%
    }

    .description {
        font-size: 11.5pt;
        margin: 15px 10px
    }

    .tag {
        font-size: 12pt;
        margin: 5px;
    }

}