.App {

  height: 100vh;
  width: 100vw;

  /* scroll-snap-type: y mandatory; */
  overflow-y: scroll;

  /* background-color: #42378f;
  background-image: linear-gradient(-300deg, #42378f 0%, #f53844 74%);

  background: hsla(248, 44%, 39%, 1);
  background: linear-gradient(45deg, hsla(248, 44%, 39%, 1) 0%, hsla(60, 53%, 71%, 1) 100%); */

  /* background-color: #010101; */

}



.App-section {

  background-color: black;
  text-align: center;

  /* This is copied from css code by /@Hyperplexed on youtube  */
  background-image: radial-gradient(rgba(255, 255, 255, 0.2) 8%,
      transparent 8%);
  background-position: 0% 0%;
  background-size: 5vmin 5vmin;
  background-attachment: fixed;


  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.title-section {
  padding: 100px 0;
  /* height: 50vh; */
}

#Name {
  font-size: 50;
}

.flex {
  display: flex;
}

.item-container {
  border: 3px solid rgba(255, 255, 255, 1);
  width: 90vw;
  /* height: 80vh; */
  border-radius: 1vw;
  margin: 10px;
  z-index: 2;
}


::-webkit-scrollbar {
  width: 0.4em;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.linksContainer {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
}

.credits {
  font-size: 14pt;
  color: #aaa;
  padding: 10px;
  text-decoration: none;
}

a {
  color: aqua;
  text-decoration: none;
}

.triangle {
  position: fixed;
  bottom: 100px;
  left: -200px;
  z-index: 0;
  opacity: 0.5;
}

@media screen and (min-width: 1920px) {
  .item-container {
    width: 70vw;
    border-radius: 1vw;
    margin: 10px;
  }
}