.skills-container {
    width: 80vw;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0vw 1vw 1vw 1vw;
    border-radius: 1vw;
}

.flex.skillCard {
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    column-gap: 10px;
    width: 190px;
    height: 200px;
    border: white solid 3px;
    border-radius: 1vw;
    margin: 10px;
    padding: 10px;
    transition: transform 0.3s ease-in-out;
    font-size: 20pt;
}

.skillCard:hover {
    transform: scale(1.1);
    border: aqua solid 3px;
}

.skillImg {
    max-width: 120px;
    max-height: 120px;
}

@media screen and (max-width: 768px) {
    .skills-container {
        width: 95vw;
    }

    .flex.skillCard {
        width: 75px;
        height: 100px;
        font-size: 12pt;
    }

    .skillImg {
        max-width: 50px;
        max-height: 50px;
    }
}